/*.top_filters{*/
/*    width: 100%;*/
/*    background-color: #cc55ff;*/
/*    !*height: 30px;*!*/
/*    text-align: center;*/
/*    padding: 20px 0;*/
/*}*/

/*.left_filters{*/
/*    width: 30%;*/
/*    background-color: #7d7d7d;*/
/*}*/

/*.main_body{*/
/*    width: 70%;*/
/*    background-color: #f5bdbd;*/
/*}*/

/*.main_container{*/
/*    display: flex;*/
/*    min-height: 70vh;*/
/*}*/

/*.sort_button{*/
/*    padding: 5px;*/
/*    margin: 9px;*/
/*    background-color: aliceblue;*/
/*    border-radius: 5px;*/
/*    cursor: pointer;*/
/*}*/

/*.items_container{*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*}*/

/*.item{*/
/*    width: 250px;*/
/*    height: 250px;*/
/*    position: relative;*/
/*    margin: 14px;*/
/*}*/

/*.item img{*/
/*    width: 100%;*/
/*}*/

/*.item .text{*/
/*    position: absolute;*/
/*    bottom: 7px;*/
/*    text-align: center;*/
/*    left: 5px;*/
/*    background-color: aqua;*/
/*}*/

.id{
    font-weight: bold;
    padding: 5px;
}

.name{
    color: #3cc601;
    padding-left: 10px;
    cursor: pointer;
}
.name_off{
    color: #c60101;
    padding-left: 10px;
    cursor: pointer;
}
.descr{
    color: grey;
    padding-left: 20px;
}

.edit_del{
    float: right;
}

.del{
    color: red;
    cursor: pointer;
}
.edit{
    color: green;
    cursor: pointer;
}

.red{
    color: #f00;
}

.black{
    color: #000;
}