.mainpart{
    width: 100%;
    position: relative;
    max-width: 1200px;
}

.top_filters{
    width: 100%;
    background-color: #cc55ff;
    /*height: 30px;*/
    text-align: center;
    padding: 20px 0;
}

.left_filters{
    /*width: max-content;*/
    background-color: #7d7d7d;
    width: 100%;
    height: 100%;
}

.main_body{
    /*width: 78vw;*/
    /*background-color: #f5bdbd;*/
}

.main_container{
    /*display: flex;*/
    min-height: 70vh;
    /*justify-content: space-between;*/
    max-width: 1200px;
    padding: 25px;
}

.sort_button{
    padding: 5px;
    margin: 9px;
    background-color: aliceblue;
    border-radius: 5px;
    cursor: pointer;
}

.items_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.item{
    width: 250px;
    height: 250px;
    position: relative;
    /*margin: 14px;*/
    cursor: pointer;
    border: 1px solid #eae9e9;
    border-radius: 5px;
    padding: 14px;
    margin: 2px;
}

.item img{
    width: 100%;
}

.item .text{
    position: absolute;
    bottom: 7px;
    text-align: center;
    left: 5px;
    background-color: aqua;
    width: 92%;
    padding: 5px;
}

.form_container{
    padding: 20px;
    white-space: nowrap;
}

.buyButton > button{
    background-color: #1fb505;
    width: 100%;
    border-color: #208a02;
    font-weight: bold;
    color: #fff;
    height: 40px;
    margin: 10px;
    border-radius: 5px;
    font-size: 19px;
}

.buyButton > button:hover,.buyButton > button:focus,.buyButton > button:active{
    background-color: #22c606;
    border-color: #249e02;
}

.old_price{
    color: grey;
    text-decoration: line-through;
}

.price{

    font-size: 25px;
    line-height: 25px;
    /*font-weight: bold;*/
}
.price_discount{
    color: red;
}

.currency{
    font-size: 18px;
}

.prices_container{
    height: 49px;
    display: flex;
}

.gallery{
    width: 300px;
    height: 300px;
    max-width: 50%;
}

.photo{
    width: 100%;
}

.name{
    font-size: 30px;
}
.name h1{
    margin: 0;
}

.name_container{
    margin: 10px;
}

.code{
    float: right;
    color: grey;
    right: 25px;
    position: absolute;
    top: 20px;
}

.item_info_container{
    display: flex;
    flex-direction: row;
}

.item_descr{
    width: 50%;
    padding-left: 20px;
}

.descr_fieldset{
    white-space: pre-line;
}

.parameter{
    font-weight: bold;
}
.no_items{
    color: #a2a2a2;
    font-size: 20px;
    height: 49px;
    padding-left: 15px;
    position: relative;
    top: 10px;
}

.favs{
    padding: 20px;
    width: 70px;
}















@media (max-width: 800px) {
    .item{
        width: 30vw;
        height: 30vw;
    }


    .item .text{
        display: none;
    }

    .name{
        font-size: 25px;
    }

}

@media (max-width: 700px) {
    .item_info_container {
        /*display: flex;*/
        flex-direction: column;
    }

    .gallery{

        /*width: 95%;*/
        /*height: 95%;*/
        max-width: 95%;
        margin: 0 auto 25px;
    }



    .item_descr{
        width: 95%;
        max-width: 95%;
    }
}

@media (max-width: 565px) {
    .item{
        width: 25vw;
        height: 25vw;
    }

}


@media (max-width: 500px) {
    .top_filters{
        line-height: 25px;
    }

    .sort_button{
        display: block;
    }

    .form_container {
        padding: 10px;
    }
}


@media (max-width: 370px) {

    .main_container{
        padding: 25px 0px;
    }
}
