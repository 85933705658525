
.title h1{
    font-weight: bold;
    font-size: 15px;
    background-color: azure;
    margin: auto;
    text-align: center;
    padding: 20px 0 0 0;
    /* border: 1px solid; */
    /*text-shadow: #ff9800 1px 0 10px;*/
    font-family: 'Secular One', sans-serif;
    text-transform: uppercase;
}

.title_part1{
    color: #7d4ba4;
}

.title_part2{
    color: #3e4066;
}


.logo{
    width: 10%;
    min-height: 100px;
    /*border: 1px solid red;*/
    background-size: 100%;
    /*background-image: url("../../imgs/logo.png");*/
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.logo img{
    width: 100%;
}

.banner_ua{
    width: 100%;
    /*height: 375px;*/
    /*background-color: #7d7d7d;*/
    background: url(../../imgs/mainBanner_ua.png) no-repeat center/100%;
    display: inline-block;
    margin-top: 0px;
    height: 36vw;
    max-height: 430px;
}
.banner_ru{
    width: 100%;
    /*height: 375px;*/
    /*background-color: #7d7d7d;*/
    background: url(../../imgs/mainBanner_ru.png) no-repeat center/100%;
    display: inline-block;
    margin-top: 0px;
    height: 36vw;
    max-height: 430px;
}
.menu_buttons_container{
    margin: auto;
    display: inline-block;
    width: 30%;

}
.menu_buttons{
    display: flex;
    margin-top: 50px;
    justify-content: space-evenly;
}

.top_line{
    background-color: azure;
    /*text-align: right;*/
    padding: 10px 5px;
    display: flex;
    justify-content: end;
    align-items: center;
    /*position: fixed;*/
    width: 100%;
    left: 0;
    border-bottom: 2px #fff outset;
    z-index: 101;
}

.top_line>div>div{
    text-align: center;
}
.top_line>div{
    display: inline-block;
}

.lang,.money,.search,.user,.pay{
    /*margin: 0 50px 0 0;*/
    cursor: pointer;
}

.pay{
    font-size: 22px;
    color: red;
}

.invisible{
    opacity: 0.1;
}

.search img {
    height: 25px;
}
.cart{
    margin-right: 20px;
    cursor: pointer;
}
.cart img{
    height: 30px;
    /*background-color: white;*/
    padding: 5px;
    /*border: 3px solid #ea0000;*/
    /*border-radius: 5px;*/
}

.buttons_line{
    text-align: center;
    /*margin: 10px 0;*/
    background-color: azure;
    padding: 10px 0;
}

.logout{
    color: red;
}

.adminLink a{
    margin: 5px;
    color: grey;
    text-decoration: none;
}


.btn {
    background: rgba(0,0,0,0);
    border: none;
    color: #000;
    /*font: 500 1rem sans-serif;*/
    padding: 5px !important;
    position: relative;
    cursor: pointer;
    margin: 1rem;
    border-radius: 10px;
}
.btnAnimation {
    /*border: 2px solid red;*/
    outline: 2px solid #aaa;
    transition: background 200ms ease;
}
.btnAnimation:hover {
    outline: 2px solid #aaf;
    /*outline-offset: 6px;*/
    background: rgb(248, 248, 255);
    /*border: 2px solid #aaf;*/
}


div .btn {
    margin: 10px 5px;
    padding: 0;
    line-height: 40px;
    white-space: nowrap;
}












@media (max-width: 1170px) {
    .menu_buttons {
        margin-top: 10px;
        flex-wrap: wrap;
    }
    .menu_buttons a {
        width: 100%;
        padding: 5px;
    }
}

@media (max-width: 900px) {
    .logo {
        width: 20vw;
    }
}
