.footer{
    background-color: #2d2d2d;
    text-align: center;
    min-height: 100px;
    width: 100%;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
}
