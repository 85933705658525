

.info{
    color: green;
    display: inline;
    opacity: 0;
    transition: opacity 1s;
}

.visible{
     opacity: 1;
 }