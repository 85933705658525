

.error{
    color: red;
    display: inline;
    opacity: 0%;
    transition: opacity 1s;
}

.visible{
     opacity: 100%;
 }