.qr{
    padding:  20px;
}

.payment_details_container{
    text-align: center;
}

.payment_details_header{
    color: black;
    font-size: 30px;
    text-decoration: underline;
}

.payment_details_text{
    font-size: 20px;
    color: grey;

    margin: auto;
    text-align: left;
}

.payment_data,.payment_details_text,.payment_details_qr{
    max-width: 70vw;
    margin: auto;
}

.payment_data{
    font-size: 20px;
    border: 1px #d2cbcb solid;
    margin: auto;
    border-radius: 5px;
    padding: 5px;
}
.order_id{
    color: black;
    font-weight: bold;
}
.payment_amount,.time_is_up{
    color: red;
    font-weight: bold;
}
.payment_amount_grey{
    color: grey;
    font-weight: bold;
}
.payment_timer{
    color: black;
    font-weight: bold;
}

.payment_details_qr{
    text-align: center;
}





.captchaDiv{
    display: block;
}

.noCaptcha{
    display: none;
}

.error{
    color: red;
}

.noError{
    display: none;
}

.sc{
    color: green;
}

.form_container{
    text-align: center;
    padding: 10px;
}

.form_container h1 {
    font-size: 30px;
    margin-bottom: 0;
}


.block_header{
    /*display: flex;*/
    /*justify-content: space-between;*/
    font-size: 25px;
    padding: 5px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    width: 100%;
}

.total{
    font-weight: bold;
    font-size: 25px;
    color: #1fb505;
    top: 4px;
    position: relative;
}

.cancel_order{
    background: #ff7a7a !important;
}
.confirm_payment{
    background: #63d95f !important;
}
.disabled_btn{
    background: #d9d9d9 !important;
    margin: 25px;
}

.cancel_order,.confirm_payment{
    color: rgba(0, 0, 0, 0.85) !important;
    margin: 25px;
}

/* statuses */
.order_paying,.order_canceled,.order_confirmed_by_user,.order_confirmed,.order_sent,.order_completed,.order_error,.order_paying{
    font-weight: bold;
}
.order_paying{
    color: black;
}
.order_canceled{
    color: red;
    background: #d9d8d8;
}
.order_confirmed_by_user{
    color: black;
    background: yellow;
}
.order_confirmed{
    color: green;
    background: #d9d8d8;
}
.order_sent{
    color: black;
    background: #8e8eff;
}
.order_completed{
    color: green;
    background: lightgreen;
}
.order_error{
    color: red;
    background: #d9d8d8;
}
.time_is_up{
    color: red;
    background: #d9d8d8;
}








@media (min-width: 1650px) {
    .payment_data,.payment_details_text,.payment_details_qr{
        max-width: 90%;
    }
}
@media (max-width: 1310px) {
    .payment_data,.payment_details_text,.payment_details_qr{
        max-width: 80vw;
    }
}
@media (max-width: 600px) {
    .payment_data,.payment_details_text,.payment_details_qr{
        max-width: 95vw;
        font-size: 16px;
    }
}
