


.search_visible{
    display: block;
}
.search_invisible{
    display: none;
}
.search_container_back_cover{
    position: fixed;
    width: 110%;
    height: 110%;
    z-index: 400;
    top: -1px;
    left: -1px;
    cursor: pointer;
    background-color: #2d2d2d;
    opacity: 0.8;
}

.search_container{
    position: fixed;
    z-index: 401;
    top: 2vh;
    left: 5vw;
    width: 90vw;
}


/*@media (max-width: 1000px) {*/
/*    .cart_container {*/
/*        width: 90vw;*/
/*        height: 90vh;*/
/*        top: 5vh;*/
/*        left: 5vw;*/
/*    }*/
/*}*/


/*@media (max-width: 770px) {*/
/*    .cart_item_name {*/
/*        font-size: 18px;*/
/*    }*/

/*    .cart_price{*/
/*        font-size: 18px;*/
/*        !*min-width: 0;*!*/
/*        margin: 0 10px;*/
/*    }*/

/*    .cart_count input{*/
/*        height: 20px;*/
/*    }*/
/*    .cart_count{*/
/*        height: 23px;*/
/*    }*/

/*}*/

/*@media (max-width: 600px) {*/
/*    .cart_item_name {*/
/*        font-size: 15px;*/
/*    }*/

/*    .cart_price {*/
/*        font-size: 15px;*/
/*        min-width: 0;*/
/*        margin: 0 0 0 10px;*/
/*        top: 2px;*/
/*        position: relative;*/
/*    }*/

/*    .cart_footer {*/
/*        text-align: center;*/
/*    }*/

/*    .total {*/
/*        font-size: 22px;*/
/*        display: block;*/
/*    }*/
/*}*/


/*@media (max-width: 500px) {*/
/*    .total {*/
/*        font-size: 20px;*/
/*    }*/
/*    .cart_container {*/
/*        width: 94vw;*/
/*        height: 94vh;*/
/*        top: 3vh;*/
/*        left: 3vw;*/
/*    }*/

/*    .cart_body {*/
/*        padding: 10px;*/
/*    }*/

/*    .cart_item {*/
/*        display: flex;*/
/*        flex-wrap: wrap;*/
/*        margin: 10px 0;*/
/*        border-bottom: 1px #e8e8e8 solid;*/
/*        padding: 5px 0;*/
/*    }*/

/*    .cart_details2 {*/
/*        padding: 10px 0 0 0;*/
/*    }*/


/*}*/


/*@media (min-width: 1000px) and (min-height:500px) {*/
/*    .cart_body {*/
/*        max-height: 50vh;*/
/*    }*/
/*}*/

/*@media (min-width: 1000px) and (max-height:499px) {*/
/*    .cart_container {*/
/*        height: 96vh;*/
/*        top: 2vh;*/
/*    }*/
/*    .cart_body {*/
/*        max-height: 50vh;*/
/*    }*/
/*}*/

/*@media (max-width: 1000px) and (min-height:500px) {*/
/*    .cart_body {*/
/*        max-height: 69vh;*/
/*    }*/
/*}*/

/*@media (max-width: 1000px) and (max-height:499px) {*/
/*    .cart_container {*/
/*        height: 96vh;*/
/*        top: 2vh;*/
/*    }*/
/*    .cart_body {*/
/*        max-height: 50vh;*/
/*    }*/
/*}*/
