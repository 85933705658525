.top_filters{
    width: 100%;
    background-color: #cc55ff;
    /*height: 30px;*/
    text-align: center;
    padding: 20px 0;
}

.left_filters{
    width: 30%;
    background-color: #7d7d7d;
}

.main_body{
    width: 70%;
    background-color: #f5bdbd;
}

.main_container{
    display: flex;
    min-height: 70vh;
}

.sort_button{
    padding: 5px;
    margin: 9px;
    background-color: aliceblue;
    border-radius: 5px;
    cursor: pointer;
}

.items_container{
    display: flex;
    flex-wrap: wrap;
}

.item{
    width: 250px;
    height: 250px;
    position: relative;
    margin: 14px;
}

.item img{
    width: 100%;
}

.item .text{
    position: absolute;
    bottom: 7px;
    text-align: center;
    left: 5px;
    background-color: aqua;
}

.my_item2{
    color: red;
    height: min-content;
}

.field_name{
    font-weight: bold;
}

.img_del{
    color: red;
}




/* statuses */
.order_paying,.order_canceled,.order_confirmed_by_user,.order_confirmed,.order_sent,.order_completed,.order_error,.order_paying,.time_is_up{
    font-weight: bold;
    padding: 5px;
}
.order_paying{
    color: #d9d8d8;
    background: green;
}
.order_canceled{
    color: red;
    background: #d9d8d8;
}
.order_confirmed_by_user{
    color: black;
    background: yellow;
}
.order_confirmed{
    color: green;
    background: #d9d8d8;
}
.order_sent{
    color: black;
    background: #8e8eff;
}
.order_completed{
    color: green;
    background: lightgreen;
}
.order_error{
    color: red;
    background: #d9d8d8;
}
.time_is_up{
    color: red;
    background: #d9d8d8;
}





.viewOrder_container{
    text-align: center;
    padding: 0 0 20px 0;
}
.viewOrder_header{
    font-weight: bold;
    font-size: 25px;
}
.viewOrder_data{
    font-size: 18px;
}
.viewOrder_subheader{
    text-decoration: underline;
}