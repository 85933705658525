


.cart_container{
    width: 70vw;
    background-color: #ffffff;
    position: fixed;
    height: 70vh;
    z-index: 401;
    top: 15vh;
    left: 15vw;
    border-radius: 10px;
}

.cart_visible{
    display: block;
}
.cart_invisible{
    display: none;
}
.cart_container_back_cover{
    position: fixed;
    width: 110%;
    height: 110%;
    z-index: 400;
    top: -1px;
    left: -1px;
    cursor: pointer;
    background-color: #2d2d2d;
    opacity: 0.8;
}

.cart_header{
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    padding: 5px;
    border-bottom: 1px solid #e8e8e8;
}
.cart_name{
    padding-left: 10px;
}
.cart_close{
    padding-right: 10px;
    cursor: pointer;
}
.cart_body{
    font-size: 20px;
    padding: 20px;
    overflow-y: scroll;
    max-height: 80%;
}

.cart_image{
    width: 15%;
    max-width: 150px;
    padding: 5px;
    display: inline-block;
}
.cart_item{
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}

.cart_item_out_of_stock{
    background-color: #ffcdcd;
}

.cart_item_name{
    padding: 5px 10px 0 10px;
    /*display: inline-block;*/
    /*position: absolute;*/
    width: 100%;
}

.cart_count{
    width: 58px;
    margin: 0 30px 0 0;
    display: flex;
    flex-wrap: nowrap;
    height: 30px;
}

.cart_details{
    display: flex;
    flex-wrap: wrap;
    width: 85%;
}
.cart_price{
    min-width: 100px;
    text-align: center;
    margin: 0 20px;
}

.cart_details1{
    width: 100%;
    display: flex;
}
.cart_details2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
}

.cart_footer{
    text-align: right;
}
.empty_cart{
    text-align: left;
    font-size: 25px;
    margin-left: 25px;
}
button.cart_submit {
    background-color: #1fb505;
    /*width: 100%;*/
    border-color: #208a02;
    font-weight: bold;
    color: #fff;
    height: 40px;
    margin: 10px;
    border-radius: 5px;
    font-size: 19px;
}

.delete{
    margin-left: 10px;
    cursor: pointer;
    color: red !important;
    height: max-content;
}

.fav{
    cursor: pointer;
    height: max-content;
}

.fav:hover,.delete:hover {
    font-size: 21px;
}

.total{
    font-weight: bold;
    font-size: 25px;
    color: #1fb505;
    top: 4px;
    position: relative;
}


.cart_count input{
    text-align: center;
}

/*
.top_filters{
    width: 100%;
    background-color: #cc55ff;
    !*height: 30px;*!
    text-align: center;
    padding: 20px 0;
}

.left_filters{
    !*width: max-content;*!
    background-color: #7d7d7d;
    width: 100%;
    height: 100%;
}

.main_body{
    !*width: 78vw;*!
    !*background-color: #f5bdbd;*!
}

.main_container{
    display: flex;
    min-height: 70vh;
    justify-content: space-between;
}

.sort_button{
    padding: 5px;
    margin: 9px;
    background-color: aliceblue;
    border-radius: 5px;
    cursor: pointer;
}

.items_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.item{
    width: 250px;
    height: 250px;
    position: relative;
    !*margin: 14px;*!
    cursor: pointer;
    border: 1px solid #eae9e9;
    border-radius: 5px;
    padding: 14px;
    margin: 2px;
}

.item img{
    width: 100%;
}

.item .text{
    position: absolute;
    bottom: 7px;
    text-align: center;
    left: 5px;
    background-color: aqua;
    width: 92%;
    padding: 5px;
}

.form_container{
    padding: 20px;
    white-space: nowrap;
}

.buyButton > button{
    background-color: #1fb505;
    width: 100%;
    border-color: #208a02;
    font-weight: bold;
    color: #fff;
}

.old_price{
    color: grey;
    text-decoration: line-through;
}

.price{

    font-size: 25px;
    line-height: 25px;
    !*font-weight: bold;*!
}
.price_discount{
    color: red;
}

.currency{
    font-size: 18px;
}

.prices_container{
    height: 49px;
}
















@media (max-width: 800px) {
    .item{
        width: 30vw;
        height: 30vw;
    }


    .item .text{
        display: none;
    }

}

@media (max-width: 565px) {
    .item{
        width: 25vw;
        height: 25vw;
    }

}


@media (max-width: 500px) {
    .top_filters{
        line-height: 25px;
    }

    .sort_button{
        display: block;
    }

    .form_container {
        padding: 10px;
    }
}
*/



@media (max-width: 1000px) {
    .cart_container {
        width: 90vw;
        height: 90vh;
        top: 5vh;
        left: 5vw;
    }
}


@media (max-width: 770px) {
    .cart_item_name {
        font-size: 18px;
    }

    .cart_price{
        font-size: 18px;
        /*min-width: 0;*/
        margin: 0 10px;
    }

    .cart_count input{
        height: 20px;
    }
    .cart_count{
        height: 23px;
    }

}

@media (max-width: 600px) {
    .cart_item_name {
        font-size: 15px;
    }

    .cart_price {
        font-size: 15px;
        min-width: 0;
        margin: 0 0 0 10px;
        top: 2px;
        position: relative;
    }

    .cart_footer {
        text-align: center;
    }

    .total {
        font-size: 22px;
        display: block;
    }
}


@media (max-width: 500px) {
    .total {
        font-size: 20px;
    }
    .cart_container {
        width: 94vw;
        height: 94vh;
        top: 3vh;
        left: 3vw;
    }

    .cart_body {
        padding: 10px;
    }

    .cart_item {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
        border-bottom: 1px #e8e8e8 solid;
        padding: 5px 0;
    }

    .cart_details2 {
        padding: 10px 0 0 0;
    }


}


@media (min-width: 1000px) and (min-height:500px) {
    .cart_body {
        max-height: 50vh;
    }
}

@media (min-width: 1000px) and (max-height:499px) {
    .cart_container {
        height: 96vh;
        top: 2vh;
    }
    .cart_body {
        max-height: 50vh;
    }
}

@media (max-width: 1000px) and (min-height:500px) {
    .cart_body {
        max-height: 69vh;
    }
}

@media (max-width: 1000px) and (max-height:499px) {
    .cart_container {
        height: 96vh;
        top: 2vh;
    }
    .cart_body {
        max-height: 50vh;
    }
}
